















































import { Component, Prop, Vue } from 'vue-property-decorator';

import { Role } from '@minddocdev/lib-common/users';
import MDButton from '@minddocdev/lib-components/components/MDButton.vue';
import MDProgressSpinner from '@minddocdev/lib-components/components/MDProgressSpinner.vue';
import { postProfileEmail } from '@minddocdev/lib-web/api/services/users';
import {
  checkIsAppAcquisition,
  getMindDocAppUrl,
  MindDocApp,
} from '@minddocdev/lib-web/browser/url.utils';
import OTPExpired from '@minddocdev/ui-accounts/components/OTPExpired.vue';

@Component({
  components: { MDProgressSpinner, OTPExpired, MDButton },
})
export default class EmailVerification extends Vue {
  @Prop({ required: true })
  otp!: string;

  loading = true;
  verified = false;
  userRoles: Role[] = [];

  $route: any;

  get isCounsellingClient() {
    return this.userRoles?.includes(Role.CounsellingClient);
  }

  get isAppAcquisition() {
    return checkIsAppAcquisition(this.$route);
  }

  redirectToTherapy() {
    let url = getMindDocAppUrl(MindDocApp.Therapy, window.location.hostname);
    if (this.isAppAcquisition) {
      url += '/app-subscription?isAppAcquisition=true';
    }
    window.location.href = url;
  }

  created() {
    this.verifyOtp();

    if (this.otp !== 'invalid') {
      this.checkUserType();
    }
  }

  checkUserType() {
    const parsedJSON = JSON.parse(atob(this.otp));
    const { roles } = parsedJSON;
    this.userRoles = roles || [];
  }

  async verifyOtp() {
    try {
      await postProfileEmail(this.otp);
      if (this.isAppAcquisition) {
        this.redirectToTherapy();
      } else {
        this.verified = true;
        this.loading = false;
      }
    } catch (e) {
      this.verified = false;
      this.loading = false;
    }
  }
}
